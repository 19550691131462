const sliders = () => {
  const Swiper = window.Swiper;

  // Slider promo
  const promo = document.querySelector(".js-promo-slider");

  if (promo) {
    const mySwiper = new Swiper(".js-promo-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 500,
      pagination: {
        el: ".js-promo-slider .swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".js-promo-slider .swiper-button-next",
        prevEl: ".js-promo-slider .swiper-button-prev",
      }
    });
  }

  // Slider tables
  const tablesBlock = document.querySelector(".js-tables-slider");

  if (tablesBlock) {
    const mySwiper = new Swiper(".js-tables-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 400,
      freeMode: true,
      navigation: {
        nextEl: ".js-new-slider .slider-btns--tables .swiper-button-next",
        prevEl: ".js-new-slider .slider-btns--tables .swiper-button-prev",
      },
      pagination: {
        el: ".js-new-slider .swiper-pagination--tables",
        clickable: true,
      },
      breakpoints: {
        460: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        680: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }

  // Slider chairs
  const chairsBlock = document.querySelector(".js-chairs-slider");

  if (chairsBlock) {
    const mySwiper = new Swiper(".js-chairs-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 400,
      freeMode: true,
      navigation: {
        nextEl: ".js-new-slider .slider-btns--chairs .swiper-button-next",
        prevEl: ".js-new-slider .slider-btns--chairs .swiper-button-prev",
      },
      pagination: {
        el: ".js-new-slider .swiper-pagination--chairs",
        clickable: true,
      },
      breakpoints: {
        460: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        680: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }

  // Slider sets
  const setsBlock = document.querySelector(".js-sets-slider");

  if (setsBlock) {
    const mySwiper = new Swiper(".js-sets-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 400,
      freeMode: true,
      navigation: {
        nextEl: ".js-new-slider .slider-btns--sets .swiper-button-next",
        prevEl: ".js-new-slider .slider-btns--sets .swiper-button-prev",
      },
      pagination: {
        el: ".js-new-slider .swiper-pagination--sets",
        clickable: true,
      },
      breakpoints: {
        460: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        680: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }

  // Slider actions
  const actionsBlock = document.querySelector(".js-actions-slider");

  if (actionsBlock) {
    const mySwiper = new Swiper(".js-actions-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 500,
      navigation: {
        nextEl: ".js-profit-slider .slider-btns--actions .swiper-button-next",
        prevEl: ".js-profit-slider .slider-btns--actions .swiper-button-prev",
      },
      pagination: {
        el: ".js-profit-slider .swiper-pagination--actions",
        clickable: true,
      },
    });
  }

  // Slider sales
  const salesBlock = document.querySelector(".js-sales-slider");

  if (salesBlock) {
    const mySwiper = new Swiper(".js-sales-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 500,
      navigation: {
        nextEl: ".js-profit-slider .slider-btns--sales .swiper-button-next",
        prevEl: ".js-profit-slider .slider-btns--sales .swiper-button-prev",
      },
      pagination: {
        el: ".js-profit-slider .swiper-pagination--sales",
        clickable: true,
      },
    });
  }

  // Slider liked-tables
  const likedTablesBlock = document.querySelector(".js-liked-tables-slider");

  if (likedTablesBlock) {
    const mySwiper = new Swiper(".js-liked-tables-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 400,
      freeMode: true,
      navigation: {
        nextEl: ".js-liked-slider .slider-btns--tables .swiper-button-next",
        prevEl: ".js-liked-slider .slider-btns--tables .swiper-button-prev",
      },
      pagination: {
        el: ".js-liked-slider .swiper-pagination--tables",
        clickable: true,
      },
      breakpoints: {
        460: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        680: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }

  // Slider liked-chairs
  const likedChairsBlock = document.querySelector(".js-liked-chairs-slider");

  if (likedChairsBlock) {
    const mySwiper = new Swiper(".js-liked-chairs-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 400,
      freeMode: true,
      navigation: {
        nextEl: ".js-liked-slider .slider-btns--chairs .swiper-button-next",
        prevEl: ".js-liked-slider .slider-btns--chairs .swiper-button-prev",
      },
      pagination: {
        el: ".js-liked-slider .swiper-pagination--chairs",
        clickable: true,
      },
      breakpoints: {
        460: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        680: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }

  // Slider liked-sets
  const likedSetsBlock = document.querySelector(".js-liked-sets-slider");

  if (likedSetsBlock) {
    const mySwiper = new Swiper(".js-liked-sets-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 400,
      freeMode: true,
      navigation: {
        nextEl: ".js-liked-slider .slider-btns--sets .swiper-button-next",
        prevEl: ".js-liked-slider .slider-btns--sets .swiper-button-prev",
      },
      pagination: {
        el: ".js-liked-slider .swiper-pagination--sets",
        clickable: true,
      },
      breakpoints: {
        460: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        680: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }

  // Slider instagram
  const instagramBlock = document.querySelector(".js-insta-slider");

  if (instagramBlock) {
    const mySwiper = new Swiper(".js-insta-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 400,
      loop: true,
      freeMode: true,
      navigation: {
        nextEl: ".js-insta-slider .swiper-button-next",
        prevEl: ".js-insta-slider .swiper-button-prev",
      },
      pagination: {
        el: ".js-insta-slider .swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        460: {
          slidesPerView: 2,
          spaceBetween: 17,
        },
        660: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        960: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
    });
  }

  const simularBlock = document.querySelector(".js-simular-slider");

  if (simularBlock) {
    const mySwiper = new Swiper(".js-simular-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 400,
      freeMode: true,
      navigation: {
        nextEl: ".js-simular-slider .swiper-button-next",
        prevEl: ".js-simular-slider .swiper-button-prev",
      },
      pagination: {
        el: ".js-simular-slider .swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        460: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        680: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }
};

export default sliders;
