const tabs = () => {
  const tabsBlock1 = document.querySelector(`.js-new-slider .tabs`);
  const tabsBlock2 = document.querySelector(`.js-profit-slider .tabs`);
  const tabsBlock3 = document.querySelector(`.js-liked-slider .tabs`);

  if (tabsBlock1) {
    let tab1 = new Tabby(`[data-tabs-1]`);
  }

  if (tabsBlock2) {
    let tab2 = new Tabby(`[data-tabs-2]`);
  }

  if (tabsBlock3) {
    let tab3 = new Tabby(`[data-tabs-3]`);
  }


  const tabsBlocks = $('.js-arrows');
  if (tabsBlocks.length > 0) {
    let index;

    tabsBlocks.each(function() {
      const tabsBlock = $(this);

      const tabsItems = tabsBlock.find(".tabs__item a");
      const btnsContainers = tabsBlock.find(".slider-btns");
      const paginationsContainers = tabsBlock.find(".swiper-pagination");

      tabsItems.each(function() {
        const item = $(this);

        item.on("click", checkSelectedItem);
      });

      function checkSelectedItem() {
        for (let i = 0; i < tabsItems.length; i++) {

          if ($(tabsItems[i]).attr("aria-selected") === "true") {
            index = i;

            btnsContainers.each(function() {
              const btnContainer = $(this);
              btnContainer.removeClass("active");

              if(btnContainer.index() === i) {
                btnContainer.addClass("active");
              }
            });

            paginationsContainers.each(function() {
              const paginationContainer = $(this);
              paginationContainer.removeClass("active");

              if(paginationContainer.index() === i) {
                paginationContainer.addClass("active");
              }
            });
          }
        }
      }
      checkSelectedItem();
    });

  }
};

export default tabs;
