const categories = () => {
  //Active state for catalogs categories
  const $linksContainer = $(".js-categories");

  if ($linksContainer) {
    const links = $(".js-categories a");

    links.each(function() {
      const $this = $(this);

      $this.on("click", function(evt) {
        evt.preventDefault();

        links.each(function() {
          $(this).removeClass("active");
        });

        if ($this.hasClass("active")) {
          $this.removeClass("active");
        } else {
          $this.addClass("active");
        }

      });
    });
  }
};

export default categories;
