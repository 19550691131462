import nodeListForEach from './node-list-for-each';
import headerScroll from './header';
import sliders from './sliders';
import tabs from './tabs';
import menuOpen from './menu-open';
import modal from './modal';
import categories from './categories';
import accordion from './accordion';
import tel from './tel';
import btnUp from './btn-up';

class App {
  static init() {
    nodeListForEach();
    headerScroll();
    sliders();
    tabs();
    menuOpen();
    modal();
    categories();
    accordion();
    tel();
    btnUp();
  }
}


App.init();
window.App = App;
